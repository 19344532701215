
body::-webkit-scrollbar {
	display: none;
  
  }
  .active {
	color: red;
  }
  
  .container { position:relative; }
  .container video {
	  position:relative;
	  z-index:0;
  }
  .overlay {
	  position:absolute;
	  top:0;
	  left: 0;
	  z-index:1;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
   
  .each-slide-effect > div {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	height: 550px;
  }
  
  .each-slide-effect span {
	padding: 20px;
	font-size: 20px;
	background: #efefef;
	text-align: center;
  }
   @tailwind base;
  @tailwind components;
  @tailwind utilities;
  